<template>
  <div class="header" :style="{ color, backgroundColor: bgColor }">
    <div v-if="showBackButton" @click="goBack" class="back-btn">
      <img src="@/assets/img/header-back-btn.png" />
    </div>
    {{ title }}
  </div>
</template>

<script>
export default {
  name: 'BaseHeaderBar',
  props: {
    title: {
      type: String,
      default: 'Pinjam Uang'
    },
    showBackButton: {
      type: Boolean,
      default: false
    },
    color: {
      type: String
    },
    bgColor: {
      type: String
    }
  },
  methods: {
    goBack () {
      this.$router.back()
    }
  }
}
</script>

<style scoped lang="less">
.header {
  position: relative;
  width: 100%;
  height: 88px;
  line-height: 88px;
  font-size: 32px;
  color: #FFF;
  text-align: center;
  z-index: 3;
}

.back-btn {
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 30px;
  cursor: pointer;

  img {
    width: 24px;
    height: 40px;
  }
}

.right-block {
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 24px;
  font-weight: 600;
  color: #FFF;
  cursor: pointer;
}
</style>
